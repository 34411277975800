import { Component, Input, ViewChild, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { PermissionManager } from '../permission-manager/permission-manager.component';
import { AlertService } from 'src/app/providers/alert/alert.service';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { RequestProviderService } from 'src/app/providers/request-provider/request-provider.service';
import { firstValueFrom } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

/**
 * Component responsible for managing file downloads, displaying progress, and handling file actions such as opening or deleting files.
 *
 * ### Available modes:
 * The `file-downloader` component distinguishes between the following 3 download modes:
 * 
 * - **`"IndirectDownload"`:** On native platforms, it displays a menu with options to download, open, or delete files. On web platforms, the file is downloaded directly.
 * Please make sure that the file can be downloaded without any authentication.
 * Please use a seperate `FileDownloader` instance in this mode for each file that should be downloaded since it contains UI elements.
 * - **`"DirectDownload"`:** On native platforms, the file is directly downloaded.
 * On web platforms, the browser decides whether to display or download the file.
 * Please make sure that the file can be downloaded without any authentication.
 * Please use a seperate `FileDownloader` instance in this mode for each file that should be downloaded since it contains UI elements.
 * - **`"AnswersheetPDFReportDownload"`:** This mode can be used to download PDF reports of a specific answersheet by using the `RequestProvider`.
 * In this mode different PDF answersheet reports can be downloaded by using one `FileDownloader` instance since it contains no UI elements for the specific files.
 * 
 * ### Input Parameters:
 * - **downloadMode (required):**
 *   Valid values are `"IndirectDownload"`, `"DirectDownload"`, and `"AnswersheetPDFReportDownload"` that are described in the "Available modes" section.
 *
 * - **downloadUrl (only required in download mode `"DirectDownload"` or `"IndirectDownload"`):**  
 *   The URL of the file to be downloaded. This parameter is mandatory.
 * 
 * - **openAfterDownload (optional, default: `false`):**  
 *   Determines whether the file should be opened automatically after download. 
 *
 * - **clearFileName (optional and only in download mode `"DirectDownload"` or `"IndirectDownload"`, default: `null`):**  
 *   The custom name to use for the downloaded file. If not specified, the original file name will be used.
 * 
 * - **faIcon (optional and only in download mode `"DirectDownload"` or `"IndirectDownload"`, default: `null`):**  
 *   FontAwesome icon definition to display alongside the download button. If not specified, no icon is displayed.
 */
@Component({
  selector: 'file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.scss'],
})
export class FileDownloader implements OnInit, OnDestroy {
  /**
   * Is required to determine whether UI elements should be displayed and how to initialize the `FileDownloader` component.
   * UI elements are only visible, if the download mode is `"DirectDownload"` or `"IndirectDownload"`.
   */
  @Input() downloadMode: DownloadMode;

  /**
   * URL of the file to be downloaded.
   * This attribute will be ignored if the downloadMode is `"AnswersheetPDFReportDownload"`.
   */
  @Input() downloadUrl?: string = null;

  /** Whether to open the file automatically after downloading. Default is `false` */
  @Input() openAfterDownload: boolean = false;

  /** 
   * Optional clear file name to use for the downloaded file.
   * If null, the original file name will be used.
   * This attribute will be ignored if the downloadMode is `"AnswersheetPDFReportDownload"`.
   */
  @Input() clearFileName?: string = null;

  /**
   * FontAwesome icon definition to display in hyperlink mode.
   * This attribute will be ignored if the downloadMode is `"AnswersheetPDFReportDownload"`.
   */
  @Input() faIcon?: IconDefinition = null;

  /** Reference to the permission manager component for handling required storage permissions. */
  @ViewChild("permissionOnboardingRef") permissionManager: PermissionManager;

  /** Tracks the download status. `true` if a file is being downloaded. */
  isDownloading: boolean = false;

  /** Indicates whether the app is running on a native platform (iOS or Android). */
  isNative = Capacitor.isNativePlatform();

  /** The menu icon to be displayed in the UI depending on the platform. */
  menuIcon: string;

  /** Tracks the state of the options menu (open or closed). */
  menuIsOpen: boolean = false;

  /** The download progress as a percentage string. */
  downloadProgress: string = "0";

  /** Array of available options for the action menu. */
  menuOptions: any[] = [];

  /** Handle for the download progress listener only on native platforms. */
  private downloadListenerHandle: PluginListenerHandle;

  /** XMLHttpRequest instance for managing web-based downloads. */
  private xhrDownloadRequest: XMLHttpRequest;

  /** File path where the downloaded file will be saved on native platforms. */
  private filePath: string;

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private requestProvider: RequestProviderService,
  ) { }

  /**
   * Initializes the component, sets the appropriate menu icon, and configures native file download progress handling.
   */
  async ngOnInit(): Promise<void> {
    if (this.isNative) {
      this.initFilePath();
      this.addDownloadProgressListener();
    }
    this.setMenuIcon();
  }

  /**
   * Cleans up resources and removes listeners when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.removeDownloadProgressListener();
  }

  /**
   * Handles menu button clicks, opening the menu on native platforms or triggering a file download on web platforms via XHR request.
   */
  menuButtonClicked(): void {
    if (this.isNative) {
      this.openMenu();
    } else {
      this.downloadFile();
    }
  }

  /**
   * Handles hyperlink clicks, opening the file if it already exists, or downloading it if not.
   */
  async anchorClicked(): Promise<void> {
    if (await this.fileExists()) {
      this.openFile(this.filePath);
    } else {
      this.downloadFile();

      if (!this.openAfterDownload) {
        this.openFile(this.filePath);
      }
    }
  }

  /**
   * Handles the selection of an option from the menu (action sheet), performing actions such as downloading, opening, or deleting a file.
   * 
   * @param event Event containing details about the selected menu option.
   */
  handleSelectedMenuOption(event): void {
    this.menuIsOpen = false;

    switch (event.detail?.data?.action) {
      case "download": {
        this.downloadFile();
        break;
      }

      case "open": {
        this.openFile(this.filePath);
        break;
      }

      case "delete": {
        this.deleteFile();
        break;
      }
    }
  }

  /**
   * Aborts an ongoing web-based download.
   */
  abortWebDownload(): void {
    this.xhrDownloadRequest?.abort();
    this.isDownloading = false;
    this.downloadProgress = "0";
    this.alertService.presentToast("SUCCESS.SUCCESS_MEDIA_DOWNLOAD_ABORTED");
  }

  /**
   * Downloads the PDF answer sheet report based on the platform (native or web).
   * 
   * @param answersheetId The ID of the answersheet to be downloaded.
   * @param title The title to be used for the downloaded file.
   */
  public downloadPDFAnswersheetReport(answersheetId: number, title: string) {
    if (this.isNative) {
      this.downloadPDFAnswersheetReportNative(answersheetId, title);
    } else {
      this.downloadPDFAnswersheetReportWeb(answersheetId, title);
    }
  }

  /**
   * Initiates the file download process based on the platform (native or web).
   */
  private downloadFile(): void {
    if (this.isNative) {
      this.downloadNative();
    } else {
      this.webDownload();
    }
  }

  /**
   * Downloads the file on a native platform using Capacitor's Filesystem API.
   * Ensures that the appropriate storage permissions are granted before proceeding.
   */
  private downloadNative(): void {
    this.permissionManager.checkAndMayAskForNativePermissions({ singlePermissions: ["storage"] })
      .then(permissionStatus => {
        if (permissionStatus.storage) {
          this.isDownloading = true;
          Filesystem.downloadFile({
            method: "GET",
            url: this.downloadUrl,
            path: this.filePath,
            directory: Directory.Documents,
            recursive: true,
            progress: true
          })
            .then(_ => {
              this.downloadProgress = "100";
              this.isDownloading = false;
              this.alertService.presentToast("SUCCESS.SUCCESS_MEDIA_DOWNLOADED");
              this.setMenuIcon();
              if (this.openAfterDownload) {
                this.openFile(this.filePath);
              }
            })
            .catch(_ => {
              this.isDownloading = false;
              this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
            });
        } else {
          this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_MISSING_FILESYSTEM_PERMISSION");
        }
      });
  }

  /**
   * Downloads the file on web platforms using an XMLHttpRequest.
   */
  private webDownload(): void {
    this.xhrDownloadRequest = new XMLHttpRequest();
    this.xhrDownloadRequest.open('GET', this.downloadUrl, true);
    this.xhrDownloadRequest.responseType = 'blob';

    this.xhrDownloadRequest.onprogress = (event) => {
      if (event.lengthComputable) {
        this.downloadProgress = ((event.loaded / event.total) * 100).toFixed(0);
      }
    };

    this.xhrDownloadRequest.onload = () => {
      if (this.xhrDownloadRequest.status === 200) {
        const blob = this.xhrDownloadRequest.response;

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.clearFileName;
        link.click();
        this.isDownloading = false
      } else {
        this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
      }
    };

    this.xhrDownloadRequest.onerror = () => {
      this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
    };

    this.isDownloading = true;
    this.xhrDownloadRequest.send();
  }

  /**
   * Initiates the download of the PDF answer sheet report on native platforms.
   * This method checks for the required storage permissions before attempting 
   * to download the report. If permissions are granted, it retrieves the report 
   * data and saves it to the device's file system.
   * 
   * @param answersheetId The ID of the answersheet to be downloaded.
   * @param title The title to be used for the downloaded PDF file.
   */
  private downloadPDFAnswersheetReportNative(answersheetId: number, title: string) {
    this.permissionManager.checkAndMayAskForNativePermissions({ singlePermissions: ["storage"] })
      .then(async permissionStatus => {
        if (permissionStatus.storage) {
          try {
            await this.alertService.presentLoading();
            const httpResponseWithPDF = await this.downloadPDFReport(answersheetId);
            const pdfBase64Data = this.arrayBufferToBase64String(httpResponseWithPDF["body"]);
            const filePath = `Report_${title}.pdf`;
            Filesystem.writeFile({
              path: `Report_${title}.pdf`,
              data: pdfBase64Data,
              directory: Directory.Documents,
              recursive: true
            });
            await this.alertService.dismissLoading();
            if (this.openAfterDownload) {
              this.openFile(filePath);
            }
          } catch (e) {
            await this.alertService.dismissLoading();
            this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
          }
        } else {
          this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_MISSING_FILESYSTEM_PERMISSION");
        }
      });
  }

  /**
   * Initiates the download of the PDF answer sheet report on web platforms.
   * It displays a loading indicator during the download process.
   * 
   * @param answersheetId The ID of the answersheet to be downloaded.
   * @param title The title to be used for the downloaded PDF file.
   */
  private async downloadPDFAnswersheetReportWeb(answersheetId: number, title: string) {
    try {
      await this.alertService.presentLoading();
      const httpResponseWithPDF = await this.downloadPDFReport(answersheetId);
      const binaryData = [];
      binaryData.push(httpResponseWithPDF["body"]);
      const downloadLink = document.createElement("a");
      const pdf = new Blob(binaryData, { type: "application/pdf" })
      downloadLink.href = window.URL.createObjectURL(pdf);
      const filename = `Report_${title}.pdf`;
      downloadLink.setAttribute("download", filename);
      document.body.appendChild(downloadLink);
      await this.alertService.dismissLoading();
      downloadLink.click();
    } catch (e) {
      await this.alertService.dismissLoading();
      this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
    }
  }

  /**
   * Sends a request to download the PDF report for the specified answersheet.
   * The request includes the answersheet ID and the user's timezone and is
   * performed by the requestProvider service.
   * 
   * @param answersheetId The ID of the answersheet for which the report is requested.
   * @returns A promise that resolves to the HTTP response containing the PDF data as an ArrayBuffer.
   */
  private async downloadPDFReport(answersheetId: number): Promise<HttpResponse<ArrayBuffer>> {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const httpRequestObservable = this.requestProvider.getAnswersheetReport(answersheetId, timezone);
    const httpResponsePromise = await firstValueFrom(httpRequestObservable);
    return httpResponsePromise;
  }

  /**
   * Converts an ArrayBuffer to a Base64 encoded string.
   * A Base64 encoded string is required for the Capacitor Filesystem plugin to store the data,
   * as binary data must be in Base64 format.
   * 
   * @param arrayBuffer The ArrayBuffer to be converted.
   * @returns A Base64 encoded string representation of the provided ArrayBuffer.
   */
  private arrayBufferToBase64String(arrayBuffer: ArrayBuffer): string {
    let binaryData = "";
    const bytes = new Uint8Array(arrayBuffer);
    bytes.forEach(data => {
      binaryData += String.fromCharCode(data);
    });
    return btoa(binaryData);
  }

  /**
   * Opens the downloaded file using the Capacitor FileOpener plugin on native platforms.
   * This function can only be used on native platforms!
   */
  private async openFile(path: string): Promise<void> {
    const fileStats = await Filesystem.stat({
      path: path,
      directory: Directory.Documents
    });
    FileOpener.openFile(
      {
        path: fileStats.uri
      }
    ).catch(_ => {
      this.alertService.presentToast("ERROR.ERROR_NO_APP_TO_OPEN_FILE");
    });
  }

  /**
   * Deletes the downloaded file using the Capacitor Filesystem API on native platforms.
   * This function can only be used on native platforms!
   */
  private async deleteFile(): Promise<void> {
    await Filesystem.deleteFile({
      path: this.filePath,
      directory: Directory.Documents
    });
    this.alertService.presentToast("SUCCESS.SUCCESS_MEDIA_DELETED");
    this.setMenuIcon();
  }

  /**
   * Opens the action menu, displaying options such as downloading, opening, or deleting the file.
   * This function can only be used on native platforms!
   */
  private async openMenu(): Promise<void> {
    this.fileExists();
    await this.determineActionSheetOptions();
    this.menuIsOpen = true;
  }

  /**
   * Determines the available options for the action menu based on whether the file already exists.
   * If the file exists, a file removal, open file and cancel option will be displayed.
   * Otherwise a donwload and cancel option will be displayed.
   * This function can only be used on native platforms!
   */
  private async determineActionSheetOptions(): Promise<void> {
    const actionSheetOptions = [];

    if (await this.fileExists()) {
      actionSheetOptions.push({
        text: this.translateService.instant("LESSON.MEDIA_DOWNLOAD_OPEN_FILE"),
        data: {
          action: 'open',
        },
      });

      actionSheetOptions.push({
        text: this.translateService.instant("LESSON.MEDIA_DOWNLOAD_DELETE_FILE"),
        role: 'destructive',
        data: {
          action: 'delete',
        },
      });
    } else {
      actionSheetOptions.push({
        text: this.translateService.instant("LESSON.MEDIA_DOWNLOAD_START"),
        data: {
          action: 'download',
        },
      });
    }

    actionSheetOptions.push({
      text: this.translateService.instant("BUTTONS.CANCEL"),
      role: 'cancel',
      data: {
        action: 'cancel',
      }
    })

    this.menuOptions = actionSheetOptions;
  }

  /**
   * Sets the menu icon based on the platform (web, Android, iOS).
   */
  private async setMenuIcon(): Promise<void> {
    if (this.downloadMode === "AnswersheetPDFReportDownload") {
      return;
    }

    if (!this.isNative) {
      this.menuIcon = "arrow-down-circle-outline";
    } else if (await this.fileExists()) {
      this.menuIcon = "checkmark-circle";
    } else {
      this.menuIcon = "arrow-down-circle-outline";
    }
  }

  /**
   * Checks whether the file already exists in the filesystem of the native platform.
   * This function can only be used on native platforms and only checks for existence
   * if the downloadMode is `"DirectDownload"` or `"IndirectDownload"`.
   * If the downloadMode is `"AnswersheetPDFReportDownload"` always `false` will be returned.
   * 
   * @returns Promise that resolves to true if the file exists, false otherwise.
   */
  private async fileExists(): Promise<boolean> {
    if ((this.downloadMode === "DirectDownload" || this.downloadMode === "IndirectDownload") && this.filePath) {
      return await Filesystem.stat({
        path: this.filePath,
        directory: Directory.Documents
      })
        .then(_ => {
          return true;
        })
        .catch(_ => {
          return false;
        });
    } else {
      return false;
    }
  }

  /**
   * Sets the file path based on the provided download URL and file name.
   * If the file has the clear file name `awesomeFile.mp4` and the hashed file name `9f327416c325a0052ad856.mp4` the filename will be concatenated with the first eight characters of the hashed file name to ensure human readable and unique filenames.
   * The result of these two file names would be `awesomeFile_9f327416.mp4`.
   * If the clear file name is nullish or an empty string, only the hashed file name will be selected as filename.
   */
  private initFilePath(): void {
    if (!this.downloadUrl || this.downloadMode === "AnswersheetPDFReportDownload") {
      return;
    }

    const lastSlashIndex = this.downloadUrl.lastIndexOf("/");
    if (lastSlashIndex === -1) {
      // URL invalid -> Makes the component invisible
      this.downloadUrl = null;
      return;
    }

    // "http://example.com/path1/path2/9f327416c325a0052ad856.mp4" -> hashedFileName = "9f327416c325a0052ad856.mp4"
    const hashedFileName = this.downloadUrl.slice(lastSlashIndex + 1);

    if (!this.clearFileName) {
      this.filePath = hashedFileName;
      return;
    }

    // "9f327416c325a0052ad856.mp4" -> fileExtension = ".mp4"
    const fileExtensionIndex = hashedFileName.lastIndexOf(".");
    let fileExtension = "";
    if (fileExtensionIndex !== -1) {
      fileExtension = hashedFileName.slice(fileExtensionIndex);
    }

    // "awesomeFile.mp4" -> clearFileNameWithoutExtension = "awesomeFile"
    let clearFileNameExtensionIndex = this.clearFileName.lastIndexOf(".");
    let clearFileNameWithoutExtension = this.clearFileName;
    if (clearFileNameExtensionIndex !== -1) {
      clearFileNameWithoutExtension = this.clearFileName.slice(0, clearFileNameExtensionIndex);
    }

    // "9f327416c325a0052ad856.mp4" -> first8CharacterOfHash = "9f327416"
    const first8CharacterOfHash = hashedFileName.slice(0, 8);

    this.filePath = `${clearFileNameWithoutExtension}_${first8CharacterOfHash}${fileExtension}`;
  }

  /**
   * Adds a listener to track the download progress using Capacitor's Filesystem API.
   * This function can only be used on native platforms!
   */
  private async addDownloadProgressListener(): Promise<void> {
    if (this.downloadMode !== "AnswersheetPDFReportDownload") {
      this.downloadListenerHandle = await Filesystem.addListener('progress', (progressStatus) => {
        this.ngZone.run(() => {
          const percentage = (progressStatus.bytes / Math.max(1, progressStatus.contentLength)) * 100;
          this.downloadProgress = percentage.toFixed(0);
        });
      });
    }
  }

  /**
   * Removes the download progress listener when it's no longer needed.
   * This function can only be used on native platforms!
   */
  private removeDownloadProgressListener(): void {
    this.downloadListenerHandle?.remove();
  }
}

/**
 * Defines the download modes for the `file-downloader` component.
 *
 * - **`"IndirectDownload"`:** Displays a menu for file actions on native platforms; downloads directly on web.
 * - **`"DirectDownload"`:** Direct download on native platforms; browser-dependent on web.
 * - **`"AnswersheetPDFReportDownload"`:** Downloads PDF reports of a specific answersheet using the `RequestProvider`, without UI elements.
 */
type DownloadMode = "DirectDownload" | "IndirectDownload" | "AnswersheetPDFReportDownload";