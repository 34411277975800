import { Lesson } from "./Lesson";
import { Answersheet } from "./Answersheet";

export class Instance {
  id: number;
  intervention_id: number;
  intervention_type: string;
  intervention_title: string;
  intervention_description: string;
  intervention_picture: string;
  ecoach_id: number;
  patient_id: number;
  configuration: Configuration;
  progress: Progress;
  created_at: Date;
  study_id: number;
  all_questionnaires?: Lesson[] = [];
  next_questionnaire_unlocks_at: Date;
  answersheets?: Answersheet[] = [];
  unlockMultiple: boolean = true;

  constructor(
    id: number,
    intervention_id: number,
    intervention_type: string,
    ecoach_id: number,
    patient_id: number,
    configuration: Configuration,
    progress: Progress,
    created_at: Date,
    intervention_title: string,
    intervention_description: string,
    intervention_picture: string,
    study_id: number,
    unlockMultiple: boolean,
  ) {
    this.id = id;
    this.intervention_id = intervention_id;
    this.intervention_type = intervention_type;
    this.ecoach_id = ecoach_id;
    this.patient_id = patient_id;
    this.configuration = configuration;
    this.progress = progress;
    this.created_at = created_at;
    this.intervention_title = intervention_title;
    this.intervention_description = intervention_description;
    this.intervention_picture = intervention_picture;
    this.study_id = study_id;
    this.unlockMultiple = unlockMultiple;
  }
}

export interface Configuration {
  questionnaire_configuration: Questionnaire_Configuration[];
  custom_order: number[];
}

export interface Questionnaire_Configuration {
  id: number;
  unlock_type: string;
  unlock_date: number;
  feedback_required: boolean;
  condition: Condition[];
  bonus_lesson: boolean;
}

export interface Condition {
  questionnaire_id: string;
  diary_id: string;
  ifBlock: any;
}

export interface Progress {
  finished_questionnaires: number[];
  unlocked_questionnaires: number[];
  current_state: string;
  feedback_provided: Feedback_Provided[];
  unlocked_questionnaires_conditionally: Object[];
}

export interface Feedback_Provided {
  questionnaire_id: number;
  thread_id: number;
  read: boolean;
  answersheet_id: number;
  feedback_required: boolean;
}
